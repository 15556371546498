import { BaseDto } from '@/shared/dtos/base-dto';

export class soporte_tikerin extends BaseDto {
    public comentario_err !: string;
    public codigo_tiket !: string;
    public fecha_resolucion!: Date;
    public fecha_alta!: Date;
    public fecha_ultima_modificacion!: Date;
    public id_vinculacion_tabla !: number;
    public id_nutricionista !: number;
    public id_estado !: number;
    public id_prioridad !: number;
    public id_tipo !: number;

} 
