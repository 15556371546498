import { store } from '@/store/store';
import { soporte_tikerin } from '@/shared/dtos/Soporte/soporte_tikerin';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'soporte_tikerinModule',
    store,
    dynamic: true
})
class soporte_tikerinModule extends VuexModule {
    public soporte_tikerins: soporte_tikerin[] = [];
    public soporte_tikerin: soporte_tikerin = new soporte_tikerin();

    @Action({ commit: 'onGetsoporte_tikerins' })
    public async getsoporte_tikerins() {
        return await ssmHttpService.get(API.soporte_tikerin);
    }

    @Action({ commit: 'onGetsoporte_tikerin' })
    public async getsoporte_tikerin(id: any) {
        return await ssmHttpService.get(API.soporte_tikerin + '/' + id);
    }

    @Action
    public async guardarsoporte_tikerin(soporte_tikerin: soporte_tikerin) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.soporte_tikerin, soporte_tikerin.toJson());
        this.getsoporte_tikerins();
    }

    @Action
    public async create_tikering_bug_dieta(soporte_tikerin: soporte_tikerin) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.soporte_tikerin+"/DietaBug", soporte_tikerin.toJson());
    }

    
    @Action
    public async modificarsoporte_tikerin(soporte_tikerin: soporte_tikerin) {
        await ssmHttpService.put(API.soporte_tikerin + '/' + soporte_tikerin.id, soporte_tikerin);
        this.getsoporte_tikerins();
    }

    @Action
    public async eliminarsoporte_tikerin(soporte_tikerin: soporte_tikerin) {
        await ssmHttpService.delete(API.soporte_tikerin + '/' + soporte_tikerin.id, null, false);
        this.getsoporte_tikerins();
    }

    @Mutation
    public onGetsoporte_tikerins(res: soporte_tikerin[]) {

        this.soporte_tikerins = res ? res.map((x) => new soporte_tikerin(x)) : []
    }

    @Mutation
    public onGetsoporte_tikerin(res: soporte_tikerin) {
        this.soporte_tikerin = new soporte_tikerin(res);
    }

}
export default getModule(soporte_tikerinModule);