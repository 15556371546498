







































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import soporte_tikerinModule from "@/store/modules/Soporte/soporte_tikerin-module";
import { soporte_tikerin } from "@/shared/dtos/Soporte/soporte_tikerin";
@Component({
  components: {},
})
export default class dialog_rpt_error_dieta extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("id_dieta", { type: Boolean }) syncedid_dieta!: number;
  public soporte: soporte_tikerin = new soporte_tikerin();

  created() {}

  public aceptar() {
    this.soporte.id_vinculacion_tabla = this.syncedid_dieta;
    soporte_tikerinModule
      .create_tikering_bug_dieta(this.soporte)
      .then(() => (this.syncedDialog = false));
    this.close;
  }

  public close() {
    this.$emit("close");
    this.syncedDialog = false;
  }
}
